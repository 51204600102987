//global css
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;600;700&family=Roboto:wght@400;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fbfbfb inset !important;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
}

input:-webkit-autofill:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
  z-index: 1;
}

.authContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  min-height: 100vh;
  background-color: #f8f8f8;
  overflow: hidden;
  position: relative;

  .authMain {
    z-index: 1;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;

    .authLogo {
      display: flex;
      justify-content: center;

      img {
        width: 193px;
        height: 80px;
        border-radius: 1px;
      }
    }

    .authForm {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      border-radius: 5px;
      padding: 45px;
      margin: auto 0px;
      width: 600px;
      height: auto;

      .wrapperButton {
        :global {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
          }
        }
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.25px;
        text-align: center;
      }

      .authSubmit {
        width: 200px;
        height: 48px;
        padding: 0px;
        border-radius: 4px;
        background-color: rgba(193, 154, 107, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 1rem;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }

    .authBottom {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: rgba(46, 46, 46, 1);
      }

      span:nth-child(2) {
        padding: 8px 0px;
      }

      span:nth-child(3) {
        color: rgba(139, 124, 120, 1);
      }
    }
  }

  .ellipse {
    background-color: #f8f29c;
    width: 71vw;
    height: 130vh;
    border-radius: 100%;
    position: absolute;
    right: -30vw;
  }
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 14px 14px !important;
}
.mgr8 {
  margin-right: 8px;
}

.mgr12 {
  margin-right: 12px;
}
.mgr16 {
  margin-right: 16px;
}
.mgl5 {
  margin-left: 5px;
}
.mg0 {
  margin: 0;
}

.width200 {
  width: 200px !important;
}

.width180 {
  width: 180px !important;
}

.actionFlex {
  display: flex;
  justify-content: end;
}
.plNoData {
  padding-left: 12px;
}

.buttonCancel,
.buttonCancel.ant-btn:hover,
.buttonCancel.ant-btn:focus {
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px solid rgba(193, 154, 107, 1);
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(193, 154, 107, 1) !important;
}

.buttonOk,
.buttonOk.ant-btn:hover,
.buttonOk.ant-btn:focus,
.buttonOk.ant-btn:active .buttonOk.ant-btn[disabled] {
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  text-align: center;
  background: rgba(193, 154, 107, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  border: none;
}

.buttonMedium {
  width: 200px;
}

.ant-notification-notice {
  width: auto !important;
}
.commonContainer {
  position: relative;
  padding-bottom: 24px;
}

.timePickerBatchSetting {
  &.ant-picker-dropdown {
    width: 150px !important;
  }

  .ant-picker-panel {
    width: 100% !important;
  }
}
.itemError {
  color: red;
  display: flex;
  margin-top: 6px;
}
.itemErrorDetailOA {
  color: red;
}
.wrapperFormFilter {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
