.pageWrapper {
  height: calc(100vh - 60px);
  display: flex;
  // position: relative;
}

.mainWrapper {
  display: flex;
  width: 100%;
  // position: relative;
  // height: calc(100vw - 60px);
  // flex: 1;
  // left: -100vw;
  // top: 7vh;
}

.pageContent,
.pageContentCollapsed {
  will-change: width;
  flex: 1;
  overflow: auto;
  background: #fff;
  // position: absolute;
  transform: translateX(0px);
}

.pageContent {
  height: calc(100vh - 60px);
  // width: calc(100vw - 237px);
  background: linear-gradient(0deg, #fbfbfb, #fbfbfb);
}

.pageContentCollapsed {
  height: calc(100vh - 60px);
  left: 80px;
  top: 60px;
  width: calc(100vw - 80px);
}
