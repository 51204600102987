.sideNav {
  height: calc(100vh - 60px);
  position: relative;
  background-color: #523b35;

  a:hover {
    color: #f1f1f1 !important;
  }

  :global {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      width: 7px;
      color: #f1f1f1;
    }

    .ant-menu-inline .ant-menu-item::after {
      border: none;
    }

    .ant-menu-item .ant-menu-item-icon,
    .ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
    .ant-menu.ant-menu-inline-collapsed .anticon {
      width: 24px !important;
      height: 24px !important;
      min-width: 24px;
    }

    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-inline {
      background-color: transparent;
    }

    .ant-menu-item a,
    .ant-menu-title-content {
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #f1f1f1;
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }

    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu.ant-menu-inline-collapsed
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      height: auto;
      padding: 11px 8px 11px 20px !important;
      margin: 0;
      display: flex;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #c19a6b;
      height: auto;
      padding: 11px 8px 11px 20px !important;
      margin: 0;
      display: flex;
    }

    .ant-menu-submenu-title:active,
    .ant-menu-item:active,
    .ant-menu-sub.ant-menu-inline {
      background: inherit;
    }

    .anticon {
      width: 12px;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      padding: 11px 8px 11px 48px !important;
    }
  }

  .logo {
    display: block;
    height: 65px;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: #f1f1f1 !important;
    padding: 20px;
  }

  .handleToggleClick {
    width: 100%;
    height: 48px;
    padding: 11px 8px 11px 20px !important;
    background-color: #3a2a26;
    display: flex;
    justify-content: end;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
  }
  .wrapperMenuItem {
    height: 100%;
    padding-top: 10px !important;
    overflow-y: auto;
    min-height: calc(100% - 40px);
    overflow-x: hidden;
    padding-bottom: 48px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #523b35;
    border-radius: 210px;
    margin: 10px 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #523b35;
    border-radius: 210px;
  }

  scrollbar-width: thin;
  scrollbar-color: #523b35 #c4c4c4;
}

.sideNavCollapsed {
  :global {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background-color: #c19a6b;
      height: auto;
      padding: 11px 8px 11px 20px !important;
      margin: 0;
      display: flex;
    }
  }

  .logo {
    font-size: 12px;
  }
}
