.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 0 0 16px;
  position: relative;
  top: 0;
  left: 0;
  background-color: #2E2E2E;
}

.logoWrapper {
  width: 85px;
  height: 35px;
  border-radius: 1px
}

.menuHeader {
  width: 60px;
  height: 60px;
  padding: 8px;
  background-color: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .textItem {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
    height: 60px;
    padding: 0px 16px;
    p {
      margin: 0px;
    }
  }

  .menuItem {
    width: 60px;
    height: 60px;
    padding: 8px;
    background-color: #585858;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}