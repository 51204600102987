.loaderContainer {
  overflow: hidden;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
  position: absolute;
  inset: 0;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  :global {
    .ant-spin-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    // .ant-spin-dot-item {
    //   background-color: #523b35;
    // }
  }
}
