.wrapperModal {
  width: auto;
  display: flex;
  justify-content: center;
  .wrapperForm {
    .itemInput {
      margin-bottom: 16px !important;
      position: relative;
      :global {
        .ant-form-item-explain {
          position: absolute;
          bottom: -5px;
        }
      }
    }
    :global {
      .ant-input {
        background-color: rgba(251, 251, 251, 1) !important;
      }
      .ant-form {
        padding: 24px;
      }
      .ant-form-item-label {
        padding: 0px;
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        position: absolute;
        right: -13px;
      }
      .ant-form-item-label > label {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(46, 46, 46, 1);
      }
      .ant-input {
        height: 44px;
      }
    }
    .wrapperButton {
      display: flex;
      margin: 0px;
      width: 100%;
      margin-top: 40px;
      :global {
        .ant-row {
          width: 100%;
        }
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: space-between;
        }
        .ant-btn {
          width: 49%;
        }
      }
    }
  }
  :global {
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-content {
      min-width: 600px;
      height: auto;
      margin: 0px 20px 0px 20px;
      border-radius: 5px 5px 0px 0px;
      .ant-modal-header {
        border-radius: 5px 5px 0px 0px;
        background: rgba(82, 59, 53, 1);
        padding: 13px 20px 13px 20px;
        .ant-modal-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: 0.5px;
          text-align: left;
          color: rgba(255, 255, 255, 1) !important;
        }
      }

      .ant-modal-close-x {
        svg {
          color: rgba(255, 255, 255, 1) !important;
          font-size: 20px;
        }
      }
    }
  }
}
